.login-auth {
    background-image: url('../../../public/media/car-login-bg.png');
    background-size: cover;
    padding: 12px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    font-weight: 200;
    
    // .container-auth {
    //     width: 300px;
    //     margin: 150px auto;
    //     background-color: white;
    //     padding: 20px;
    //     border-radius: 10px;
    //     width: 550px;
    //     height: 550px;


    //     h2 {
    //         text-align: left;
    //     }

    //     .form-group {
    //         margin-bottom: 20px;
    //     }

    //     .form-group input[type="text"],
    //     .form-group input[type="password"] {
    //         width: 100%;
    //         padding: 10px;
    //         border: 2px solid purple;
    //         border-radius: 25px;
    //     }

    //     .remember-forgot {
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;
    //     }

    //     .remember-me {
    //         order: 1;
    //     }

    //     .forgot-password {
    //         order: 2;
    //     }
    // }
    .form-group{
        margin-bottom: 0;
    }
    span.error{
        font-family: "Avenir-Medium" !important;
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
        text-align: left;
    }

    /* Common styles */
    .form-container {
        max-width: 480px;
        margin: 150px auto;
        padding: 40px;
        background: white;
        border-radius: 25px;
        box-shadow: 0px 8px 64px rgba(28, 29, 31, 0.1);

        @media (max-width:820px) {
            margin: 28px auto;
            padding: 40px;
            background: white;
            border-radius: 25px;
            width: 90%;
        }

        @media (max-width:575px) {
            margin: 0;
            border-radius: 0 !important;
            width: 100%;
            max-width: 100%;
            padding: 20px !important;
        }
    }

    h4 {
        text-align: left;
        color: #000000;
    }

    h3 {
        text-align: left;
        color: #000000;
        font-size: 28px;
        margin-bottom: 26px;
    }

    .border-outline-fb {
        border: 1px solid #2A0A4D !important;
        color: #5C666F;
        font-size: 15px;
        border-radius: 25px;
        padding: 10px;

        i {
            color: #2A0A4D;
            margin-right: 10px;
        }
    }

    label {
        display: flex;
        font-size: 15px;
        white-space: nowrap;
        // margin-top: 10px;
        color: black;
    }

    input,
    textarea {
        width: 100%;
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    input[type="submit"] {
        width: auto;
        margin-top: 20px;
    }

    /* Desktop styles */


    /* Tablet styles */
    @media only screen and (max-width: 820px) {

        background-image: none;
        background: linear-gradient(to bottom, #F2F1EF 50%, #E2E42B 50%);
        color: #000000;


    }

    /* Mobile styles */
    @media only screen and (max-width: 575px) {

        padding: 0;
    }


}

.form-group {
    margin-bottom: 20px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
    width: 100%;
    font-size: 15px;
    padding: 10px 10px 10px 19px;
    background-color: white;
    border: 1px solid #2A0A4D;
    border-radius: 25px;
}

.remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remember-me {
    order: 1;
}

.forgot-password {
    color: #2A0A4D;
    text-decoration: none;
    font-weight: 200 !important;
    order: 2;
    font-size: 15px;
}

.border-top-login {
    border-top: 1px solid #DBDCE3;

    width: 50%;
    white-space: nowrap;
    margin-top: 12px;
}

.signin-text {
    color: black;
    white-space: nowrap;
    margin: 0 12px;
    font-weight: 200;
}

.login-btn {
    width: 100% !important;
    height: 46px !important;
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
    accent-color: #2A0A4D;
    margin: 0;

    clip-path: circle(61% at 50% 50%);
}

input[type=checkbox]:checked {
    accent-color: #2A0A4D;
}

input[type=checkbox]:hover {
    accent-color: #2A0A4D;
}
.error{
    color:red;
}
p.error{
    display: block;
    color:red;
}
p.success{
    color: green;
}
.register{
    margin-top: 10px;
    color:#5C666F;
    font-size: 14px;
}
.register a{
    color:#2A0A4D;
}